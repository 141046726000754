/**
 * Button
 */


.mi-button {
  position: relative;
  padding: 0 15px;
  background: transparent;
  color: #ffffff;
  border: none;
  display: inline-block;
  //z-index: 1;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 2px;
  //height: $input-button-height;
  line-height: $input-button-height;
  transition: $transition;


    &::before {
    content: "";
    position: absolute;
    left: 0;
    top: auto;
    bottom: 3px;
    height: 2px;
    width: 100%;
    background: $color-theme;
    //z-index: 1;
    transform: scaleX(0);
    //transform-origin: 0 0;
    transition: $transition;
    }


  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 3px;
    bottom: 0;
    height: 2px;
    width: 100%;
    background: $color-theme;
    //z-index: 1;
    transform: scaleX(0);
    //transform-origin: 0 0;
    transition: $transition;
    border-right: none;
  }

    &:hover {
      color: $color-theme;
      border-right: #00af97;

    }

    &:hover::before {
      transform: scaleX(1);
      border-right: #00af97;
    }

    &:hover::after {
      transform: scaleX(1);
      border-right: #00af97;
    }

    @media #{$layout-mobile-lg} {
      height: $input-button-height - 5;
      line-height: $input-button-height - 5;
    }
  }

.mi-readmore {
  position: relative;
  color: #ffffff;
  transition: $transition;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: auto;
    bottom: 0;
    height: 1px;
    width: 0;
    background: $color-theme;
    transition: $transition;
  }

  &:hover {
    color: $color-theme;

    &::after {
      width: 100%;
    }
  }
}